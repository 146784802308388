<template>
  <b-card-code title="" no-body>
    <b-card-body>
      <h3>Total Jadwal krip : {{ rows }}</h3>
    </b-card-body>

    <b-card-body>
      <b-row>
        <b-col md="2" xl="2" class="mb-1">
          <!-- durasi -->
          <b-form-group label="durasi" label-for="durasi">
            <b-form-spinbutton
              id="sb-inline"
              v-model="filter.kripjadwal_durasi"
            />
          </b-form-group>
        </b-col>
        <b-col md="2" xl="2" class="mb-1">
          <!-- status pendaftaran jadwal krip -->
          <b-form-group label="status" label-for="status">
            <v-select
              v-model="filter.kripjadwal_status"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusList"
            />
          </b-form-group>
        </b-col>
        <b-col md="2" xl="2" class="mb-1">
          <!-- link -->
          <b-form-group label="link" label-for="link">
            <b-form-input
              id="basicInput"
              placeholder="Search link zoom"
              v-model="filter.kripjadwal_link"
            />
          </b-form-group>
        </b-col>
        <b-col md="2" xl="2" class="mb-1">
          <!-- tanggal -->
          <b-form-group label="tanggal" label-for="tanggal">
            <b-form-input
              id="filter-tanggal"
              v-model="filter.kripjadwal_tanggal"
              class="mb-1"
              type="date"
            />
          </b-form-group>
        </b-col>
        <b-col md="2" xl="2" class="mb-1">
          <!-- jam -->
          <b-form-group label="jam" label-for="jam">
            <b-form-timepicker v-model="filter.kripjadwal_jam" locale="en" />
          </b-form-group>
        </b-col>
        <b-col lg="2" md="3" class="mb-25">
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-primary"
            class="mt-0 mt-md-2"
            @click="refreshPage()"
          >
            <feather-icon icon="SearchIcon" class="mr-25" />
            <span>Search</span>
          </b-button>
        </b-col>
      </b-row>

      <b-button-toolbar
        class="d-flex justify-content-between flex-wrap pt-0"
        aria-label="Toolbar with button groups and dropdown menu"
      >
        <!-- group -->
        <b-button-group>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="reloadPage"
          >
            <feather-icon icon="RefreshCwIcon" />
          </b-button>
        </b-button-group>
      </b-button-toolbar>
    </b-card-body>

    <b-card-body>
      <b-row>
        <b-col lg="20" md="12" class="mb-50">
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-primary"
            class="mt-2 mt-md-0"
            @click="addKrip()"
          >
            <feather-icon icon="PlusIcon" class="mr-26" />
            <span>Tambah Data Krip</span>
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>

    <!-- table -->
    <b-table
      ref="selectableTable"
      selectable
      :select-mode="selectMode"
      :items="items"
      :fields="fields"
      responsive
      class="mb-0"
      @row-selected="onRowSelected"
    >
      <template #cell(document)="data">
        <a :href="data.item.document" v-if="data.item.document" target="_blank"
          >Open</a
        >
      </template>
      <template #cell(status)="data">
        <b-badge :variant="status[1][data.value]">
          {{ status[0][data.value] }}
        </b-badge>
      </template>
      <template #cell(action)="data">
        <b-dropdown variant="link" no-caret toggle-class="p-0" right>
          <template #button-content>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
            >
              <feather-icon icon="SettingsIcon" />
            </b-button>
          </template>

          <b-dropdown-item @click="modalUpdateJadwalKrip(data.item)">
            <feather-icon icon="CheckSquareIcon" size="16" />
            <span class="align-middle ml-50">Edit</span>
          </b-dropdown-item>
          <b-dropdown-item @click="lihatPeserta(data.item)">
            <feather-icon icon="UsersIcon" size="16" />
            <span class="align-middle ml-50">Lihat Peserta</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <div></div>
      <!--pagination -->
      <div class="demo-inline-spacing pt-0">
        <b-pagination
          v-model="currentPage"
          hide-goto-end-buttons
          :total-rows="rows"
          :per-page="perPage"
        />
      </div>
    </b-card-body>
    <b-modal id="modal-export" hide-footer>
      <template #modal-title> Input Email Tujuan </template>
      <div class="d-block text-center">
        <p>
          Data yang akan diexport lebih dari <b>500</b> baris dan akan dikirim
          via email, Silahkan masukkan email anda!
        </p>
      </div>
      <b-form-group>
        <label for="email">Email:</label>
        <b-form-input
          id="email"
          type="email"
          placeholder="Email Address"
          v-model="email"
        />
      </b-form-group>
      <b-button
        class="mt-3"
        variant="outline-primary"
        block
        @click="exportData()"
      >
        <feather-icon icon="DownloadCloudIcon" /> Export
      </b-button>
    </b-modal>

    <!-- edit jadwal krip-->
    <b-modal
      id="modal-update-jadwal-krip"
      title="Edit Jadwal Krip"
      cancel-variant="outline-secondary"
      hide-footer
    >
      <b-form @submit.prevent>
        <b-form-input
          id="id"
          v-model="updateJadwalKrip.id"
          placeholder="id"
          hidden
        />
        <b-form-group label="tempat" label-for="vue-select">
          <b-form-input
            id="tempat"
            v-model="updateJadwalKrip.kripjadwal_tempat"
            placeholder="tempat"
          />
        </b-form-group>
        <b-form-group label="Tanggal" label-for="vue-select">
          <b-form-input
            id="update-jadwal-tanggal"
            v-model="updateJadwalKrip.kripjadwal_tanggal"
            class="mb-1"
            type="date"
          />
        </b-form-group>
        <b-form-group label="Waktu" label-for="vue-select">
          <b-form-timepicker
            v-model="updateJadwalKrip.kripjadwal_jam"
            locale="en"
          />
        </b-form-group>
        <b-form-group label="Durasi" label-for="vue-select">
          <b-form-spinbutton
            id="sb-inline"
            v-model="updateJadwalKrip.kripjadwal_durasi"
            inline
          />
        </b-form-group>
        <b-form-group label="Limit" label-for="vue-select">
          <b-form-spinbutton
            id="sb-inline"
            v-model="updateJadwalKrip.kripjadwal_limit"
            inline
          />
        </b-form-group>
        <b-form-group label="zoom_link" label-for="vue-select">
          <b-form-input
            id="zoom_link"
            v-model="updateJadwalKrip.kripjadwal_link"
            placeholder="zoom_link"
          />
        </b-form-group>
        <b-form-group label="zoom_id" label-for="vue-select">
          <b-form-input
            id="zoom_id"
            v-model="updateJadwalKrip.kripjadwal_zoom_id"
            placeholder="zoom_id"
          />
        </b-form-group>
        <b-form-group label="zoom_password" label-for="vue-select">
          <b-form-input
            id="zoom_password"
            v-model="updateJadwalKrip.kripjadwal_pass"
            placeholder="zoom_password"
          />
        </b-form-group>

        <b-form-group label="status" label-for="vue-select">
          <v-select
            id="status"
            v-model="updateJadwalKrip.kripjadwal_status"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="optionsJadwalKrip"
            placeholder="status.."
          />
        </b-form-group>
        <br />
        <b-button
          variant="primary"
          type="submit"
          block
          :disabled="invalid"
          @click="updateDataJadwalKrip"
        >
          Update Jadwal Krip
        </b-button>
      </b-form>
    </b-modal>

    <!-- add jadwal krip-->
    <b-modal
      id="modal-add-jadwal-krip"
      title="Tambah Jadwal Krip"
      cancel-variant="outline-secondary"
      hide-footer
    >
      <b-form @submit.prevent>
        <b-form-group label="tempat" label-for="vue-select">
          <b-form-input
            id="tempat"
            v-model="kripjadwal_tempat"
            placeholder="tempat"
          />
        </b-form-group>
        <b-form-group label="Tanggal" label-for="vue-select">
          <b-form-input
            id="create-data-datepicker"
            v-model="kripjadwal_tanggal"
            class="mb-1"
            type="date"
          />
        </b-form-group>
        <b-form-group label="Waktu" label-for="vue-select">
          <b-form-timepicker v-model="kripjadwal_jam" locale="en" />
        </b-form-group>
        <b-form-group label="Durasi" label-for="vue-select">
          <b-form-spinbutton
            id="sb-inline"
            v-model="kripjadwal_durasi"
            inline
          />
        </b-form-group>
        <b-form-group label="Limit" label-for="vue-select">
          <b-form-spinbutton id="sb-inline" v-model="kripjadwal_limit" inline />
        </b-form-group>
        <b-form-group label="zoom_link" label-for="vue-select">
          <b-form-input
            id="zoom_link"
            v-model="kripjadwal_link"
            placeholder="zoom_link"
          />
        </b-form-group>
        <b-form-group label="zoom_id" label-for="vue-select">
          <b-form-input
            id="zoom_id"
            v-model="kripjadwal_zoom_id"
            placeholder="zoom_id"
          />
        </b-form-group>
        <b-form-group label="zoom_password" label-for="vue-select">
          <b-form-input
            id="zoom_password"
            v-model="kripjadwal_pass"
            placeholder="zoom_password"
          />
        </b-form-group>

        <b-form-group label="status" label-for="vue-select">
          <v-select
            id="status"
            v-model="kripjadwal_status"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="optionsJadwalKrip"
            placeholder="status.."
          />
        </b-form-group>
        <br />
        <b-button
          variant="primary"
          type="submit"
          block
          :disabled="invalid"
          @click="addJadwalKrip"
        >
          Add Jadwal Krip
        </b-button>
      </b-form>
    </b-modal>

    <!-- list peserta-->
    <b-modal
      id="modal-list-perserta"
      size="xl"
      title="List Peserta"
      cancel-variant="outline-secondary"
      hide-footer
    >
      <p>
        <b> Total Peserta : {{ pesertaRows }}</b>
      </p>
      <b-table
        ref="selectableTable"
        selectable
        :select-mode="selectMode"
        :items="persertas"
        :fields="list_pesertas"
        responsive
        class="mb-0"
        @row-selected="onRowSelected"
      >
        <template #cell(document)="data">
          <a
            :href="data.item.document"
            v-if="data.item.document"
            target="_blank"
            >Open</a
          >
        </template>
        <template #cell(status)="data">
          <b-badge :variant="status[1][data.value]">
            {{ status[0][data.value] }}
          </b-badge>
        </template>
        <template #cell(action)="data">
          <b-dropdown variant="link" no-caret toggle-class="p-0" right>
            <template #button-content>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon"
              >
                <feather-icon icon="SettingsIcon" />
              </b-button>
            </template>
          </b-dropdown>
        </template>
      </b-table>
      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <div></div>
        <!--pagination -->
        <div class="demo-inline-spacing pt-0">
          <b-pagination
            v-model="pesertaCurrentPage"
            hide-goto-end-buttons
            :total-rows="pesertaRows"
            :per-page="pesertaPerPage"
          />
        </div>
      </b-card-body>
    </b-modal>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BTable,
  BButton,
  BFormGroup,
  BAvatar,
  BBadge,
  BCardBody,
  BPagination,
  BFormTextarea,
  BFormFile,
  BButtonToolbar,
  BButtonGroup,
  BDropdown,
  BDropdownItem,
  BFormInput,
  BRow,
  BCol,
  BModal,
  BFormDatepicker,
  BInputGroup,
  BInputGroupAppend,
  BCardText,
  BFormTimepicker,
  BFormSpinbutton,
} from "bootstrap-vue";
import API from "@/api";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCardCode,
    BTable,
    BButton,
    BAvatar,
    BCardBody,
    BBadge,
    vSelect,
    BPagination,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BFormFile,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BModal,
    BFormTextarea,
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    BCardText,
    BFormTimepicker,
    BFormSpinbutton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      currentPage: 1,
      rows: 0,
      perPage: 20,
      // lihat peserta
      pesertaCurrentPage: 1,
      pesertaRows: 0,
      pesertaPerPage: 20,
      modes: ["multi", "single", "range"],
      fields: [
        { key: "id", label: "Id" },
        [{ key: "kripjadwal_tanggal", label: "tanggal" }],
        [{ key: "kripjadwal_tempat", label: "tempat" }],
        [{ key: "kripjadwal_jam", label: "jam" }],
        [{ key: "kripjadwal_durasi", label: "durasi" }],
        [{ key: "kripjadwal_link", label: "link" }],
        [{ key: "kripjadwal_zoom_id", label: "zoom ID" }],
        [{ key: "kripjadwal_pass", label: "password" }],
        [{ key: "kripjadwal_status", label: "status" }],
        [{ key: "kripjadwal_limit", label: "limit" }],
        "action",
      ],
      /* eslint-disable global-require */
      items: [],
      /* eslint-disable global-require */

      //lihatPeserta
      list_pesertas: [
        { key: "id", label: "Id" },
        "orang_id",
        "kripjadwal_id",
        "created_at",
        "updated_at",
      ],
      persertas: [],

      status: [
        {
          0: "Register",
          1: "Verify",
          2: "Can't find",
          3: "Input wrong data",
        },
        {
          0: "light-primary",
          1: "light-success",
          2: "light-warning",
          3: "light-warning",
          5: "light-info",
        },
      ],
      selectMode: "multi",
      selected: [],
      search: null,
      updateJadwalKrip: {},
      optionsJadwalKrip: [
        "pendaftaran-dibuka",
        "pendaftaran-ditutup",
        "selesai",
      ],
      formatted: "",
      selected: "",
      filter: {
        kripjadwal_tanggal: null,
        kripjadwal_jam: null,
        kripjadwal_durasi: null,
        kripjadwal_zoom_id: null,
        kripjadwal_tempat: null,
        kripjadwal_link: null,
      },
      statusList: ["pendaftaran-dibuka", "pendaftaran-ditutup", "selesai"],
    };
  },
  methods: {
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no-date-selected` string
      this.formatted = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD;
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    refreshPage() {
      if (this.currentPage == 1) {
        this.getJadwalKrip();
        this.lihat_peserta();
      } else {
        this.currentPage = 1;
      }
    },
    reloadPage() {
      location.reload();
    },
    formatNames(files) {
      if (files.length === 1) {
        return files[0].name;
      }
      return `${files.length} files selected`;
    },

    modalUpdateJadwalKrip(item) {
      this.updateJadwalKrip = item;
      this.$bvModal.show("modal-update-jadwal-krip");
    },
    lihatPeserta(item) {
      this.lihat_peserta(item);
      this.$bvModal.show("modal-list-perserta");
    },

    addKrip() {
      this.$bvModal.show("modal-add-jadwal-krip");
    },

    async getJadwalKrip() {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);

        const form = {
          limit: this.perPage,
          page: this.currentPage,
          search: this.search,
          kripjadwal_tanggal: this.filter.kripjadwal_tanggal,
          kripjadwal_jam: this.filter.kripjadwal_jam,
          kripjadwal_durasi: this.filter.kripjadwal_durasi,
          kripjadwal_tempat: this.filter.kripjadwal_tempat,
          kripjadwal_link: this.filter.kripjadwal_link,
          kripjadwal_status: this.filter.kripjadwal_status,
        };
        const { data } = await API.jadwal_krip.list(form);
        this.data_table = data;
        this.items = data.data;
        this.rows = data.total;

        this.handleMsgSuccess("Berhasil tampilkan data Jadwal krip");
      } catch (error) {
        console.log(error);
        this.handleMsgErrors(error, "Terjadi kesalahan silahkan coba lagi!");
      } finally {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },

    async lihat_peserta(ids) {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);

        var id = ids.id;
        const queryData = {
          limit: this.pesertaPerPage,
          page: this.pesertaCurrentPage,
          search: this.search,
        };
        const { data } = await API.jadwal_krip.list_peserta(id, queryData);
        this.data_table = data;
        this.persertas = data.data;
        this.pesertaRows = data.total;
        this.handleMsgSuccess("Berhasil tampilkan data list peserta");
      } catch (error) {
        this.handleMsgErrors(error, "gagal list peserta");
      } finally {
        this.clearSelected();
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },

    async addJadwalKrip() {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);

        let body = {
          kripjadwal_tempat: this.kripjadwal_tempat,
          kripjadwal_tanggal: this.kripjadwal_tanggal,
          kripjadwal_jam: this.kripjadwal_jam,
          kripjadwal_durasi: this.kripjadwal_durasi,
          kripjadwal_limit: this.kripjadwal_limit,
          kripjadwal_link: this.kripjadwal_link,
          kripjadwal_zoom_id: this.kripjadwal_zoom_id,
          kripjadwal_pass: this.kripjadwal_pass,
        };
        const { data } = await API.jadwal_krip.add(body);
        this.handleMsgSuccess("Berhasil Add Jadwal Krip");
        this.refreshPage();
      } catch (error) {
        this.handleMsgErrors(error, "gagal add Krip");
      } finally {
        this.$bvModal.hide("modal-add-jadwal-krip");
        this.clearSelected();
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },

    async updateDataJadwalKrip() {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);

        var ids = this.updateJadwalKrip.id;

        let body = {
          kripjadwal_tempat: this.updateJadwalKrip.kripjadwal_tempat,
          kripjadwal_tanggal: this.updateJadwalKrip.kripjadwal_tanggal,
          kripjadwal_jam: this.updateJadwalKrip.kripjadwal_jam,
          kripjadwal_durasi: this.updateJadwalKrip.kripjadwal_durasi,
          kripjadwal_limit: this.updateJadwalKrip.kripjadwal_limit,
          kripjadwal_link: this.updateJadwalKrip.kripjadwal_link,
          kripjadwal_zoom_id: this.updateJadwalKrip.kripjadwal_zoom_id,
          kripjadwal_pass: this.updateJadwalKrip.kripjadwal_pass,
          kripjadwal_status: this.updateJadwalKrip.kripjadwal_status,
        };
        const { data } = await API.jadwal_krip.updateData(ids, body);
        this.handleMsgSuccess("Berhasil Update Jadwal Krip");
        this.refreshPage();
      } catch (error) {
        console.log(error);
        this.handleMsgErrors(error, "gagal update Krip");
      } finally {
        this.$bvModal.hide("modal-update-jadwal-krip");
        this.clearSelected();
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },
  },
  mounted() {
    this.getJadwalKrip();
    this.lihat_peserta();
  },
  watch: {
    currentPage() {
      this.getJadwalKrip();
    },
  },
};
</script>

<style lang="scss">
.b-table-selectable {
  .feather {
    font-size: 1.3rem;
  }
}
</style>
